@font-face {
font-family: 'avantGardeGothic';
src: url(/_next/static/media/080c27def53c111c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'avantGardeGothic';
src: url(/_next/static/media/b96302de4f37ac2a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'avantGardeGothic Fallback';src: local("Arial");ascent-override: 88.16%;descent-override: 23.07%;line-gap-override: 0.00%;size-adjust: 111.39%
}.__className_5c95e1 {font-family: 'avantGardeGothic', 'avantGardeGothic Fallback', Arial, sans-serif
}.__variable_5c95e1 {--avant-garde-gothic: 'avantGardeGothic', 'avantGardeGothic Fallback', Arial, sans-serif
}

